<template>
    <b-card>
      <h4 class="mb-0">
        Users Profile
      </h4>
      <validation-observer ref="validUpdate">
        <b-form class="mt-1" @submit.prevent="validationForm">
          <b-row>
            <b-col md="6">              
              <b-form-group label="Nama" label-for="name">
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="dataStudent.name" :state="errors.length > 0 ? false : null"
                    placeholder="Nama" type="text" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Email" label-for="email">
                <validation-provider #default="{ errors }" name="email" rules="required">
                  <b-form-input id="email" v-model="dataStudent.email" :state="errors.length > 0 ? false : null"
                    placeholder="Email" type="text" disabled/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Jenis Kelamin" label-for="gender">
                <validation-provider #default="{ errors }" name="gender" rules="required">
                  <b-form-input id="gender" v-model="dataStudent.gender" :state="errors.length > 0 ? false : null"
                    placeholder="Jenis Kelamin" type="text" disabled/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Tanggal Lahir" label-for="birth">
                <validation-provider #default="{ errors }" name="birth" rules="required">
                  <b-form-input id="birth" v-model="dataStudent.birth" :state="errors.length > 0 ? false : null"
                    placeholder="Tanggal Lahir" type="text" disabled/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>           
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="info" class="mr-1"
                @click="() => $router.go(-1)">
                Back
              </b-button>            
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!-- <b-container> -->
  
      <!-- </b-container> -->
    </b-card>
  </template>
  
  <script>
  import {
    // BContainer,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BFormInvalidFeedback,
    BForm,
    BButton,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import dataCountries from '@/assets/json/countries.json'
  import dataProvinces from '@/assets/json/provinces.json'
  import dataCities from '@/assets/json/cities.json'
  import dataDistrics from '@/assets/json/districts.json'
  import dataSubDistrics from '@/assets/json/subdistricts.json'
  
  export default {
    name: 'DataSiswaEdit',
    components: {
      ValidationProvider,
      ValidationObserver,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      // BContainer,
      // BFormCheckbox,
      BForm,
      BButton,
      vSelect,
      BFormInvalidFeedback,
    },
    directives: {
      Ripple,
    },
    data: () => ({
      listSiswa: [],
      ListBK: [],
      ListGuruBK: [],
      listBackground: [],
      name: '',
      dataStudent: {
        name: '',
        email: '',
        gender: '',
        birth: '',      
      },
    }),
    computed: {
  
    },
    created() {
      this.getStudentDetail()      
    },
    methods: {     
      async getStudentDetail() {
        try {
          const studentId = this.$route.params.id
          const response = await this.$http.get(`/auth/me`)
          console.log(response.data.user)
          this.fillStudentData(response.data.user)
        } catch (err) {
          console.log(err)
        }
      },
      fillStudentData(data) {
        this.dataStudent.name = data.name       
        this.dataStudent.email = data.email       
        this.dataStudent.gender = data.gender       
        this.dataStudent.birth = data.birth       
      },
      validationForm() {
        this.$refs.validUpdate.validate().then(async success => {
          if (success) {
            // eslint-disable-next-line
            try {
              const studentId = this.$route.params.id
              const dataFormStudent = new FormData()
              Object.keys(this.dataStudent).forEach(key => {
                dataFormStudent.append(key, this.dataStudent[key])
              })
              dataFormStudent.append('_method', 'PUT')
              this.$http.put(`/studentcasestudies/${studentId}`, dataFormStudent, {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              })
              // alertnya()
            } catch (err) {
              console.log(err)
            }
          }
  
          // function alertnya() {
          //   alert('form submitted!')
          // }
        })
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import "~@core/scss/vue/libs/vue-select.scss";
  </style>
  