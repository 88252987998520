var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Users Profile ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Nama","type":"text","disabled":""},model:{value:(_vm.dataStudent.name),callback:function ($$v) {_vm.$set(_vm.dataStudent, "name", $$v)},expression:"dataStudent.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"placeholder":"Email","type":"text","disabled":""},model:{value:(_vm.dataStudent.email),callback:function ($$v) {_vm.$set(_vm.dataStudent, "email", $$v)},expression:"dataStudent.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Jenis Kelamin","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"gender","state":errors.length > 0 ? false : null,"placeholder":"Jenis Kelamin","type":"text","disabled":""},model:{value:(_vm.dataStudent.gender),callback:function ($$v) {_vm.$set(_vm.dataStudent, "gender", $$v)},expression:"dataStudent.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"birth"}},[_c('validation-provider',{attrs:{"name":"birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"birth","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Lahir","type":"text","disabled":""},model:{value:(_vm.dataStudent.birth),callback:function ($$v) {_vm.$set(_vm.dataStudent, "birth", $$v)},expression:"dataStudent.birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"info"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Back ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }